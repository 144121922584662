body {
    font-size: 13px;
}

.content {
	padding:15px;
}

.left-padding-50 {
    padding-left: 50px;
}

.label-table {
    display: inline-block;
    width: 100px;
}

.bootstrap-timepicker {
    min-height: 30px;
}

.img-contract {
  margin-bottom: 20px;
}

.ct-chart {
    height: 300px;
}

.graph-container {
    height: 300px;
}

.dashboard-container {
    .info-box {
        box-shadow: none;
    }
}

.btn-top-form {
    margin-left: 5px;
    margin-right: 5px;
}

input.ng-invalid.ng-dirty {
    background-color: #FFB9B0;
}

.container-filters {
    display: none;
}


.content-header > .breadcrumb {
    right: initial;
    font-size: 13px;
}

.content-header {
    height: 35px;
}

.chosen-container-single .chosen-single {
    height: 30px;
    line-height: 29px;
}

div.stretchy-wrapper {
	width: 100%;
	padding-bottom: 71.5%;
	position: relative;

}

div.stretchy-wrapper > div {
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
}

#image-preview {
    /* position: relative; */
    overflow: hidden;
    background-color: #ffffff;
    color: #E1E1E1;
    border: 1px solid #E1E1E1;
    cursor: pointer;
    margin-bottom: 30px;

    input {
        line-height: 200px;
        font-size: 200px;
        position: absolute;
        opacity: 0;
        z-index: 10;
        cursor: pointer;
    }

    label {
        position: absolute;
        z-index: 5;
        opacity: 0.9;
        background-color: #bdc3c7;
        width: 200px;
        height: 50px;
        font-size: 20px;
        line-height: 50px;
        text-transform: uppercase;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
    }
}

@media (min-width: $screen-md-min) {

    .left-padding-50 {
        padding-left: 50px;
    }

}

@media (min-width: $screen-lg-min) {

    .box-list {

        table {

            select {
                width: 140px;
            }
        }
    }
}
