.contract {
    position: relative;
    background: #fff;
    border: 1px solid #f4f4f4;
    padding: 20px;
    margin: 10px 25px;
    font-size: 13px;


    address {
        margin-bottom: 0;
    }

    hr {
        margin-top: 10px;
    }

    .contract-signatures {
        margin-top: 50px;
        margin-bottom: 20px;

        hr {
            border-color: darkgray;
        }
    }

    .contract-disclaimer {
        font-size: 10px;
    }

    .contract-alvara {
        text-align: center;
        font-size: 10px;

        hr {
            margin: 8px 0;
        }
    }

    @media print {
        width: 900px;
        border: none;
    }
}

.print.contract-conditions {

    background: #fff;
    border: 1px solid #f4f4f4;
    padding: 20px;
    margin: 10px 25px;
    page-break-before: always;
    page-break-after: always;
    font-size: 12px;
    color: #424242;

    h2 {
        font-size: 20px;
        color: gray;
        margin-bottom: 25px;
        border-bottom: 1px solid lightgray;
    }

    ul {
        padding-left: 25px;
    }

    @media print {
        font-size: 10pt;
        border: none;
    }
}
