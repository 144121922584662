
.button-highlight {
    background-color: #445C66;
    color: white !important;
    margin: 2px !important;
}

@media (min-width: $screen-xs-max) {
	  
    .main-sidebar {
        width: 175px;
    }

    .main-header > .navbar {
        margin-left: 175px;
    }

    .main-header .logo {
        width: 175px;
    }
    .content-wrapper, .right-side {
        margin-left: 175px;
    }

    .main-footer {
        margin-left: 175px;
    }
}