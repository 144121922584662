.dropzone .dz-preview .dz-image {
	border-radius: 0px;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
	border-radius: 0px;	
}

#showhere {
	width:100%;
	min-height: 150px;
	background-color: #ECECEC;
}

.dz-progress {
    visibility: hidden;
}
